const moment = require('moment')

export function getSiteUrl(siteData) {
    let sslInstalled = false;
    if (sslInstalled) {
        return `https://www.${siteData.domain}`;
    }
    else {
        return `http://${makeSystemDomain(siteData.domain)}.${getTld()}`;
    }

}

export function getSystemDomain(siteData) {

    return `http://${makeSystemDomain(siteData.domain)}.${getTld()}`;
}


function makeSystemDomain(domain) {
    return domain != undefined ? domain.replace('.', '-') : 'bad';
}

function getTld() {
    return 'gowebcms.com';
}

export function formatTime(timestamp) {
    const format = null;
    return moment(timestamp).format(format)

}