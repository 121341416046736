import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);
import styles from "styles/styles"
import Table from "components/Table/Table.js";
import { Link, useHistory } from 'react-router-dom'

import { Typography } from "@material-ui/core";


import useNotification from 'hooks/useNotification';


import { Button } from "@material-ui/core";
import { apiLoadClientSiteAdmins, apiDeleteClientSiteAdmin } from "_api"

export default function AdminList(props) {
    const history = useHistory();
    const classes = useStyles();

    const { addNotification } = useNotification();
    const adminHeaders = [
        'ID', 'Username', 'First Name', 'Last Name', 'Email'
    ]

    const [isLoading, setLoading] = useState(true);
    const [adminData, setAdminData] = useState([]);
    useEffect(() => {
        apiLoadClientSiteAdmins(props.id).then((ret) => {
            setAdminData(ret.data.rows)
        })
    }, []);

    const deleteAdmin = (id) => {
        apiDeleteClientSiteAdmin(id).then(resp => {

            if (resp.data.status === true) {
                addNotification('Successfully deleted', 'success')
                setAdminData(adminData.filter(v => v.id != id))
            }
            else {
                addNotification('Updating failed', 'error')

            }


        })

    }

    const goToCreateAdmin = (id) => {
        history.push(`/admin/client/site/admin/create/${id}`)
    }

    return (
        <div >

            <Typography align="right">
                <Button variant="contained" color="primary" onClick={() => goToCreateAdmin(props.id)}>New Admin Account</Button>
            </Typography>
            <Table
                tableHeaderColor="primary"
                tableHead={adminHeaders}
                tableData={adminData.map(v => {
                    return [
                        v.id,
                        <Link to={`/admin/admin/edit/${v.id}`}> {v.username}</Link>,
                        v.first_name,
                        v.last_name,
                        v.primary_email,
                        v.country,
                        v.created_at,
                        <span>
                            <Button href={`/admin/client/site/admin/edit/${v.id}`} variant="contained" color="primary"> Edit</Button>
                            <Button onClick={() => deleteAdmin(v.id)} variant="contained" color="secondary"> Delete</Button>
                        </span>
                    ]
                })}
            />
        </div >
    )


}