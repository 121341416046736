
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";


import NotificationProvider from './providers/NotificationProvider';
import Notification from './components/Notification';

// core components
import Admin from "./layouts/Admin.js";
import RTL from "layouts/RTL.js";

import "./assets/css/material-dashboard-react.css?v=1.10.0";

ReactDOM.render(
  <NotificationProvider>

    <BrowserRouter>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
    <Notification />
  </NotificationProvider>,
  document.getElementById("root")
);
