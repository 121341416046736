import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import LinearProgress from '@material-ui/core/LinearProgress'

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(styles);
import styles from "styles/styles"
import { useParams } from "react-router-dom";

import { apiLoadClient } from "_api"

import PublicTemplateSetList from 'components/Template/PublicTemplateSetList'

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';


import { useHistory } from "react-router-dom";


import { apiInstallClientSite } from '_api';
import Domain from 'components/ClientSite/Domain'
import SiteName from 'components/ClientSite/SiteName'
import { apiCreateClientSite } from "_api";



export default function ClientSiteCreate() {
  const classes = useStyles();

  const { id } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [clientData, setClientData] = useState([]);




  const history = useHistory();

  const [siteData, setSiteData] = useState({
    domain: '',
    template_set_id: null,
    name: ''
  });

  const createSite = () => {
    setLoading(true);
    apiCreateClientSite({
      ...siteData,
      template_set_id: siteData.template_set_id.id,
      client_id: id,
      culture: 'en_us'
    }).then(v => {
      console.log('vv', v)
      setLoading(false)

      apiInstallClientSite(v.data.client_site.id).then(() => {
        history.push(`/admin/client/site/view/${v.data.client_site.id}`);
      })
    })
  }


  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    'Select Template',
    'Enter Domain Details',
    'General Information',
  ]

  const updateStepData = (field, value) => {
    console.log('update step data', field, value)
    setSiteData((prevState => ({
      ...prevState,
      [field]: value
    })))
    setActiveStep(activeStep + 1)
    console.log('tuka bace', siteData)

  }

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <GridItem xs={12} sm={12} md={12}>
            <Typography align="center" paragraph={true}>Select one of the templates below to start creating content</Typography>
            <PublicTemplateSetList btnlabel="Finish" update={updateStepData} />
          </GridItem >
        );

      case 1:
        return (
          <GridItem xs={12} sm={12} md={6}>
            <Domain btnlabel="Next" update={updateStepData} />
          </GridItem>
        );
      case 2:
        return (
          <GridItem xs={12} sm={12} md={6}>
            <SiteName btnlabel="Next" update={updateStepData} />
          </GridItem >
        );
      default:
        return 'Unknown stepIndex';
    }
  }



  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };






  useEffect(() => {
    apiLoadClient({
      id: id
    }).then(data => {

      if (data.data.count > 0) {

        setClientData(data.data.rows[0])

      }
      setLoading(false)
    })
  }, [])

  return (
    <div>


      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/admin/clients">
          Clients
        </Link>
        <Link
          color="textPrimary"
          aria-current="page"
          href={`/admin/client/site/${clientData?.id}`}
        >
          Client "{clientData?.name}"
        </Link>
        <Link
          color="textPrimary"
          aria-current="page"
        >
          New site
        </Link>
      </Breadcrumbs>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Create Site </h4>
        </CardHeader>
        <CardBody>
          {isLoading ? <LinearProgress /> :
            <div className="site-data-wrapper">





              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <GridContainer alignContent="space-between" alignItems="center" justifyContent="center">
                {activeStep === steps.length ? (
                  <GridItem xs={6} sm={6} md={6}>
                    <Typography className={classes.instructions}>All steps completed</Typography>
                    <table>
                      <tbody>
                        <tr>
                          <th>Domain</th>
                          <td>{siteData.domain}</td>
                        </tr>
                        <tr>
                          <th>Name</th>
                          <td>{siteData.name}</td>
                        </tr>
                        <tr>
                          <th>Template</th>
                          <td>{siteData.template_set_id.name}</td>
                        </tr>
                      </tbody>
                    </table>
                    <Button onClick={() => createSite()} color="primary">Install</Button>
                    <Button onClick={() => handleReset()} color="danger">Reset</Button>
                  </GridItem>
                ) : (
                  getStepContent(activeStep)
                )}
              </GridContainer>
            </div>
          }
        </CardBody>
      </Card>



    </div >
  );
  //<Typography tag="div" className={classes.instructions}>{getStepContent(activeStep)}</Typography>
}
/*
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.backButton}
                      >
                        Back
              </Button>
                      <Button variant="contained" color="primary" onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>

                      */