import React, { useEffect, useState } from "react";
import TemplateSet from 'components/Template/TemplateSet'

import { apiLoadPublicTemplateSetList } from "_api";

export default function PublicTemplateList(props) {
    const [limit, setLimit] = useState(10);
    const [listing, setListing] = useState([]);
    const [isLoading, setLoading] = useState(true);


    useEffect(() => {

        apiLoadPublicTemplateSetList({
            limit: limit,
        }).then(data => {
            setListing(data.data.rows)
            setLoading(false)
        })

    }, [limit])


    return (
        <div className="template-listing">
            {
                listing.map(v => {
                    return (<TemplateSet data={v} update={props.update} key={'template_set_'+v.id} />)
                })
            }
        </div>

    )
}