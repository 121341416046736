import React, { useEffect, useState } from "react";
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Pagination from '@material-ui/lab/Pagination';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import LinearProgress from '@material-ui/core/LinearProgress'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Table from "components/Table/Table.js";
import TablePagination from '@material-ui/core/TablePagination';


import { formatTime } from "_util"

import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);
import styles from "styles/styles"

import { apiLoadClientSites, apiLoadClient } from "_api"

import { useParams } from "react-router-dom";


export default function ClientSites(props) {
  const classes = useStyles();

  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [listing, setListing] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [clientData, setClientData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowsPerPageOptions = [
    5, 10, 50
  ]


  const headers = ["ID", "Name", "Domain", "Installed", "SSL", "Created", "Actions"]

  let { id } = useParams();
  useEffect(() => {
    apiLoadClient({
      id: id
    }).then(data => {

      if (data.data.count > 0) {

        setClientData(data.data.rows[0])

        apiLoadClientSites({
          limit: limit,
          client_id: id,
          page: page
        }).then(data => {
          setListing(data.data.rows)
          setLoading(false)

          /*
            const totalNew = Math.ceil(parseInt(data.data.count) / limit)
          setTotalPages( 
            totalNew
            )
          console.log('total pages', totalNew)
          */
          setTotalItems(data.data.count)


        })
      }
    })
  }, [limit, page])

  return (
    <div>

      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/admin/clients">
          Clients
        </Link>
        <Link
          color="textPrimary"
          aria-current="page"
        >
          Client "{clientData?.name}"
        </Link>
        <Button href={`/admin/client/view/${clientData.id}`}>
          Sites
        </Button>
      </Breadcrumbs>


      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Sites</h4>

            </CardHeader>
            <CardBody>
              {isLoading && <LinearProgress />}

              <Typography align="right">
                <Button color="primary" variant="contained" href={`/admin/client/site/create/${clientData.id}`}>Create a new site now!</Button>
              </Typography>


                {listing.length > 0 ?(

              <GridContainer>
                <Table
                  tableHeaderColor="primary"
                  tableHead={headers}
                  tableData={listing.map(v => {
                    return [
                      v.id,
                      //         <Link to={`/admin/client/view/${v.id}`}> {v.name}</Link>,
                      v.name,
                      v.domain,
                      v.installed,
                      v.ssl,
                      formatTime(v.created_at),
                      <span>

                        <Button href={`/admin/client/site/view/${v.id}`} variant="contained" color="primary"> Manage Site</Button>
                      </span>
                    ]
                  })}
                />

                <TablePagination
                  component="div"
                  count={totalItems}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}

                  rowsPerPageOptions={rowsPerPageOptions}
                />

              </GridContainer>

                ) 
                : <Typography align="center" >No sites found. Use the button above to create a new site</Typography>}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

                //<Pagination count={totalPages} variant="outlined" shape="rounded" onChange={setPage}/>