const config = {
    apiDevHost: '127.0.0.1',
    apiProdHost: 'api.gowebcms.com',
    //mode: 'prod',
    mode: process.env.mode === 'dev'? 'dev': 'prod',
    useSsl: true,
    devPort: 1234,
    prodPort: 443,
}


function apiHost() {

    let url = 'http';
    if (config.useSsl && config.mode === 'prod') {
        url += 's';
    }

    url += '://';

    if (config.mode == 'dev') {
        url += config.apiDevHost
        url += ':' + config.devPort
    }
    else {
        url += config.apiProdHost
        url += ':' + config.prodPort
    }


    url += '/';

    return url;

}

exports.apiHost = apiHost
