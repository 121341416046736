import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";

import useNotification from 'hooks/useNotification';

const useStyles = makeStyles(styles);
import styles from "styles/styles"

import { apiUpdateClientSiteAdmin } from "_api"


import { Controller, useForm } from "react-hook-form";

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';





const adminEditSchema = Joi.object({
    primary_email: Joi.string().required().email({ tlds: { allow: false } }),
    first_name: Joi.string().required().min(3),
    last_name: Joi.string().required().min(3),

    /*
    username: Joi.string().required().email({ tlds: { allow: false } }),

    password: Joi.string().min(8).max(15).required(),
    password_confirm: Joi.any().equal(Joi.ref('password')).required().label('Confirm password').messages({ 'any.only': '{{#label}} does not match' })
    */
});



export default function AdminEditGeneral(props) {
    const classes = useStyles();

    const { addNotification } = useNotification();

    const { register, handleSubmit, watch, control, setValue, formState: { errors } } = useForm(
        {
            resolver: joiResolver(adminEditSchema),
        }
    );


    const onSubmitGeneral = data => {
        console.log('onsubmit client')
        if (Object.keys(errors).length === 0) {
            apiUpdateClientSiteAdmin(props.id, data).then(resp => {
                if (resp.data.status === true) {

                    addNotification('Successfully updated admin account', 'success')
                }
            })
        }

    }

    useEffect(() => {
        setValue('primary_email', props.primary_email)
        setValue('first_name', props.first_name)
        setValue('last_name', props.last_name)

    }, [props])


    return (

        <form onSubmit={handleSubmit(onSubmitGeneral)}>

            <GridContainer>

                <GridItem xs={12} sm={12} md={6} >
                    <Controller
                        name="primary_email"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="primary_email" value={value} error={!!error} helperText={error ? error.message : "Enter your email"} label="Email" fullWidth />}
                    />

                </GridItem>


                <GridItem xs={12} sm={12} md={6} >
                    <Controller
                        name="first_name"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="first_name" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter First Name "} label="First Name" fullWidth />}
                    />
                </GridItem>

                <GridItem xs={12} sm={12} md={6} >
                    <Controller
                        name="last_name"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="last_name" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter Last Name "} label="Last Name" fullWidth />}
                    />
                </GridItem>

                <GridItem xs={12} sm={12} md={12} >
                    <Button variant="contained" type="submit" color="primary">Update</Button>
                </GridItem>

            </GridContainer>
        </form >

    );
}
