import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles(styles);
import styles from "styles/styles"

import { apiUpdateClientSiteAdminPassword } from "_api"

import { Controller, useForm } from "react-hook-form";

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';


import useNotification from 'hooks/useNotification';
const adminEditPasswordSchema = Joi.object({
  password: Joi.string().min(8).max(15).required(),
  password_confirm: Joi.any().equal(Joi.ref('password')).required().label('Confirm password').messages({ 'any.only': '{{#label}} does not match' })

});



export default function AdminEditPassword(props) {
  const classes = useStyles();


  const { addNotification } = useNotification();
  const { setError, handleSubmit, watch, control, setValue, formState: { errors } } = useForm(
    {
      resolver: joiResolver(adminEditPasswordSchema),
    }
  );


  const onSubmitClient = data => {
    if (Object.keys(errors).length === 0) {
      apiUpdateClientSiteAdminPassword(props.id, data).then((response) => {
        if (response.data.status === true) {
          addNotification('Success', 'success')
        }
        else {
          const errorMsg = response.data?.message;
          setError('password', {
            type: 'server',
            message: errorMsg
          })


          addNotification('Failed updating password ', 'error');
        }
      }).catch(err => {
      })
    }

  }



  return (

    <form onSubmit={handleSubmit(onSubmitClient)}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>

              <GridContainer>

                <GridItem xs={12} sm={12} md={6} >
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="password" type="password" value={value} error={!!error} helperText={error ? error.message : "Password"} label="password" fullWidth />}
                  />

                </GridItem>
                <GridItem xs={12} sm={12} md={6} >
                  <Controller
                    name="password_confirm"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="password_confirm" type="password" value={value} error={!!error} helperText={error ? error.message : "Password confirmation"} label="password" fullWidth />}
                  />

                </GridItem>



              </GridContainer>

            </CardBody>
            <CardFooter>

              <Button variant="contained" type="submit">Update Password</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </form >
  );
}
