var axios = require('axios')
import { handleResponse } from '_helpers';
import { getHeaders } from '_api';
import { apiHost } from './config'

var qs = require("qs");

import { useHistory } from "react-router-dom";

import { userService } from "_services/user.service"



axios.interceptors.response.use((response) => {
    return response;
}, (error) => {

    if (!error.response) {
        return Promise.reject('Network error')
    }
    const errorCodes = [401, 403];


    if (error?.response?.status == 400) {
        console.log(error)
        alert('hwana go')
        response.data.status = false;
        return response;
    }

    if (error?.response?.status == 422) {
    }

    if (error?.response?.status == 422) {
        const response = error.response;
        console.log('FAILED resolving data', response)
        response.data.status = false;
        return response;
        return Promise.resolve(response)
    }

    console.log('error code', error?.response?.status)
    if (errorCodes.includes(error?.response?.status)) {
        console.log('Logout code detected')

        //const history = useHistory();
        userService.logout();

        //useHistory().push('/admin/login')
        window.location = '/admin/login'
        return Promise.reject(error);

    }

    else {
        console.log('3tiq if')
        return Promise.reject(error);
    }
});

export function apiLoadPrivateTemplateSetList(payload) {
    const URL = apiHost() + `api/reseller/privatetemplateset?limit=` + payload.limit;
    return axios
        .get(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}


export function apiLoadPublicTemplateSetList(payload) {
    const URL = apiHost() + `api/reseller/publictemplateset?limit=` + payload.limit;
    return axios
        .get(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}



export function apiLoadClients(payload) {
    const URL = apiHost() + `api/reseller/client?limit=` + payload.limit;
    return axios
        .get(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}


export function apiLoadClient(payload) {
    const URL = apiHost() + `api/reseller/client/view/${payload.id}`;
    return axios
        .get(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}


export function apiLoadClientSite(payload) {
    const URL = apiHost() + `api/reseller/clientsite/view/${payload.id}`;
    return axios
        .get(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}

export function apiCreateClientSite(payload) {
    const URL = apiHost() + `api/reseller/clientsite`;
    return axios
        .post(URL, qs.stringify(payload), {
            headers: getHeaders()
        }).then(handleResponse)
}

export function apiCreateClient(payload) {
    const URL = apiHost() + `api/reseller/client`;
    return axios
        .post(URL, qs.stringify(payload), {
            headers: getHeaders()
        }).then(handleResponse)
}


export function apiUpdateClient(id, payload) {
    const URL = apiHost() + `api/reseller/client/view/${id}`;
    return axios
        .patch(URL, qs.stringify(payload), {
            headers: getHeaders()
        }).then(handleResponse)
}



export function apiCountClientSites(payload) {
    const URL = apiHost() + `api/reseller/clientsitescount/${payload.client_id}`;
    return axios
        .get(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}


export function apiLoadClientSites(payload) {
    const URL = apiHost() + `api/reseller/clientsite/${payload.client_id}?limit=${payload.limit}&page=${payload.page+1}`;
    return axios
        .get(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}


//admins -> all
export function apiLoadClientSiteAdmins(id) {
    const URL = apiHost() + `api/reseller/clientsite/admin/${id}`;
    return axios
        .get(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}


//certain admin by id
export function apiLoadClientSiteAdmin(id) {
    const URL = apiHost() + `api/reseller/clientsite/admin/view/${id}`;
    return axios
        .get(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}

export function apiExportClientSite(id, shared = true) {
    const URL = apiHost() + `api/reseller/clientsite/exportsite/${id}/${shared ? 1 : 0}`;
    return axios
        .get(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}


export function apiDeleteClientSite(id) {
    const URL = apiHost() + `api/reseller/clientsite/${id}`;
    return axios
        .delete(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}


export function apiDeleteClient(id) {
    const URL = apiHost() + `api/reseller/client/${id}`;
    return axios
        .delete(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}


export function apiDeleteClientSiteAdmin(id) {
    const URL = apiHost() + `api/reseller/clientsite/admin/${id}`;
    return axios
        .delete(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}


export function apiUpdateClientSiteAdmin(id, payload) {
    const URL = apiHost() + `api/reseller/clientsite/admin/${id}`;
    return axios
        .patch(URL, qs.stringify(payload), {
            headers: getHeaders()
        }).then(handleResponse)
}

export function apiUpdateClientSiteAdminUsername(id, payload) {
    const URL = apiHost() + `api/reseller/clientsite/admin/username/${id}`;
    return axios
        .patch(URL, qs.stringify(payload), {
            headers: getHeaders()
        }).then(handleResponse)
}



export function apiUpdateClientSiteAdminPassword(id, payload) {
    const URL = apiHost() + `api/reseller/clientsite/admin/password/${id}`;
    return axios
        .patch(URL, qs.stringify(payload), {
            headers: getHeaders()
        }).then(handleResponse)
}



export function apiCreateClientSiteAdmin(id, payload) {
    const URL = apiHost() + `api/reseller/clientsite/admin`;
    payload.client_site_id = id;
    return axios
        .post(URL, qs.stringify(payload), {
            headers: getHeaders()
        }).then(handleResponse)
}

export function apiCreateClientSiteCheckUnique(payload) {
    const URL = apiHost() + `api/reseller/clientsite/domaincheck`;
    return axios
        .post(URL, qs.stringify(payload), {
            headers: getHeaders()
        }).then(handleResponse)
}

export function apiInstallClientSite(id) {
    const URL = apiHost() + `api/reseller/clientsite/install/${id}`;
    return axios
        .get(URL, {
            headers: getHeaders()
        }).then(handleResponse)
}

