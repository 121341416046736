import React, { useEffect } from "react"
import useNotification from 'hooks/useNotification';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
export default function Notification() {



    const { notification, removeNotification } = useNotification();

    useEffect(() => {

        if (notification && notification.message) {

            switch (notification.status) {
                case 'info':
                    NotificationManager.info(notification.message);
                    break;
                case 'success':
                    NotificationManager.success(notification.message/*, 'Title here'*/);
                    break;
                case 'warning':
                    NotificationManager.warning(notification.message/*, 'Close after 3000ms', 3000*/);
                    break;
                case 'error':
                    NotificationManager.error(notification.message/*, 'Click me!', 5000, () => {

                    }*/);
                    break;
            }
        }
    })
    return (
        <NotificationContainer />
    )
}