/*!

te
=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import LoginForm from "views/Login/Login.js";
import ClientList from "views/Client/ClientList";
import ClientEdit from "views/Client/ClientEdit";
import ClientCreate from "views/Client/ClientCreate";
import ClientSiteList from "views/ClientSite/ClientSiteList";
import ClientSiteEdit from "views/ClientSite/ClientSiteEdit";
import ClientSiteCreate from "views/ClientSite/ClientSiteCreate";
import AdminEdit from "views/Admin/AdminEdit.js";
import AdminCreate from "views/Admin/AdminCreate.js";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    isLogged: true,
    menu: true,
  },
  {
    isLogged: false,
    path: "/login",
    name: "Login",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: LoginForm,
    layout: "/admin",
    menu: false,
  },

  /*
  {
    isLogged: false,
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
  */
  {
    isLogged: true,
    path: "/clients",
    name: "Clients",
    rtlName: "قائمة الجدول",
    icon: "user",
    component: ClientList,
    layout: "/admin",
    menu: true,
  },

  {
    isLogged: true,
    path: "/client/site/admin/create/:id",
    name: "Admin create ",
    rtlName: "قائمة الجدول",
    icon: "user",
    component: AdminCreate,
    layout: "/admin",
    menu: false,
  },
 
  {
    isLogged: true,
    path: "/client/site/admin/edit/:id",
    name: "Admin edit",
    rtlName: "قائمة الجدول",
    icon: "user",
    component: AdminEdit,
    layout: "/admin",
    menu: false,
  },
 
{
    isLogged: true,
    path: "/client/site/create/:id",
    name: "Create Site",
    rtlName: "قائمة الجدول",
    icon: "user",
    component: ClientSiteCreate,
    layout: "/admin",
    menu: false,
  },
  {
    isLogged: true,
    path: "/client/site/view/:id",
    name: "View Site",
    rtlName: "قائمة الجدول",
    icon: "user",
    component: ClientSiteEdit,
    layout: "/admin",
    menu: false,
  },
  {
    isLogged: true,
    path: "/client/site/:id",
    name: "Client Sites",
    rtlName: "قائمة الجدول",
    icon: "user",
    component: ClientSiteList,
    layout: "/admin",
    menu: false,
  },



  {
    isLogged: true,
    path: "/client/view/:id",
    name: "View Client",
    rtlName: "قائمة الجدول",
    icon: "user",
    component: ClientEdit,
    layout: "/admin",
    menu: false,
  },

  {
    isLogged: true,
    path: "/client/create",
    name: "Create Client",
    rtlName: "قائمة الجدول",
    icon: "user",
    component: ClientCreate,
    layout: "/admin",
    menu: false,
  },


  /*
  {
    isLogged: true,
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin",
  },
  {
    isLogged: true,
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin",
  },
  {
    isLogged: true,
    path: "/maps",
    name: "Maps",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
  },
  {
    isLogged: true,
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
  },
  */


];

export default dashboardRoutes;
