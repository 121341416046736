import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { getSystemDomain, formatTime } from '_util/siteUtils'
import { Button } from "@material-ui/core";
export default function ClientSiteData(props) {

    const siteData = props.siteData

    const installSite = () => {
        //apiInstallClientSite(siteData.id).then(()=> {
         window.location.reload();   
        //})
    }

    return (
        <div>


            <table>
                <tbody>
                    <tr>
                        <th>Domain Name</th>
                        <td>{siteData.domain}</td>
                    </tr>
                    <tr>
                        <th>System Name</th>
                        <td><a href={getSystemDomain(siteData)} target="_blank">{getSystemDomain(siteData)}</a></td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>{siteData.installed ? <span><CheckCircleOutlineIcon /> Installed <span className="date">{formatTime(siteData.installed_date)}</span></span> : <span><ErrorOutlineIcon /> Not installed</span>}</td>
                    </tr>
                    <tr>
                        <th>Created</th>
                        <td>{formatTime(siteData.created_at)}</td>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr>
                        <th>Main domain status</th>
                        <td>{
                            siteData.main_site_installed
                                ?
                                <span><CheckCircleOutlineIcon /> Installed <span className="date">{formatTime(siteData.main_installed_date)}</span></span>
                                :
                                <span><ErrorOutlineIcon /> Not installed {siteData.main_site_installed_locked ? <span className="locked-error">Locked</span> :
                                    (<Button onClick={()=> installSite()}>Install</Button>)}</span>}
                        </td>
                    </tr>
                    <tr>
                        <th>SSL status</th>
                        <td>{siteData.ssl_installed ? (<span>
                            YES
                            {formatTime(siteData.ssl_installed_date)}
                        </span>) : 'no'}</td>
                    </tr>
                </tbody>
            </table>


        </div>
    )
}