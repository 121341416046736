import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import { joiResolver } from '@hookform/resolvers/joi';
const validations = require('validation')

import { Controller, useForm } from "react-hook-form";

export default function SiteName(props) {
    const { setError, handleSubmit, watch, control, setValue, formState: { errors } } = useForm(
        {
            resolver: joiResolver(validations.site_name),
        }
    );


    const onSubmitGeneral = data => {
        if (Object.keys(errors).length === 0) {
            props.update('name', data.name);
        }

    }


    return (

        <form onSubmit={handleSubmit(onSubmitGeneral)}>
            <Typography align="center" paragraph={true}>Enter general information below</Typography>

            <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="name" value={value} error={!!error} helperText={error ? error.message : "Please enter your site name name"} label="Site Name" fullWidth/>}
            />

            <Button variant="contained" type="submit">{props.btnlabel}</Button>
        </form>
    )


}