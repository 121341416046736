import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import { joiResolver } from '@hookform/resolvers/joi';
const validations = require('validation')
import Typography from '@material-ui/core/Typography';

import { apiCreateClientSiteCheckUnique } from '_api'

import { Controller, useForm } from "react-hook-form";

export default function Domain(props) {
    const { setError, handleSubmit, watch, control, setValue, formState: { errors } } = useForm(
        {
            resolver: joiResolver(validations.domain),
        }
    );


    const onSubmitGeneral = data => {
        if (Object.keys(errors).length === 0) {
            apiCreateClientSiteCheckUnique(data).then(response => {
                console.log('tuka', response)
                if (response.data.status == true) {
                    props.update('domain', data.domain);
                }
                else {
                    setError('domain', {
                        type: 'server',
                        message: 'This domain is already taken and cannot be used'
                    })


                }
            })
        }

    }


    return (

        <form onSubmit={handleSubmit(onSubmitGeneral)}>
            <Typography align="center" paragraph={true}>Enter your domain name below. If you don't have one yet, don't worry! You will get a system domain from us for free!</Typography>

            <Controller
                name="domain"
                control={control}
                defaultValue=""
                render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="domain" value={value} error={!!error} helperText={error ? error.message : "Please enter your domain name"} label="Domain" fullWidth/>}
            />

            <Button variant="contained" type="submit" color="primary">{props.btnlabel}</Button>
        </form>
    )


}