import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";


import TextField from "@material-ui/core/TextField";

import styles from "styles/styles"

import { Controller, useForm } from "react-hook-form";

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

const schema = Joi.object({
    email: Joi.string().required().email({ tlds: { allow: false } }),
    password: Joi.string().required().min(3)
});

import { useHistory } from "react-router-dom";

//import {apiLogin} from "_api"
import { userService } from "_services/user.service"

const useStyles = makeStyles(styles);
const LoginForm = (props) => {
    const classes = useStyles();
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm(
        {
            resolver: joiResolver(schema),
        }
    );

    const history = useHistory();

    const onSubmit = data => {
        if (Object.keys(errors).length === 0) {
            userService.login(data.email, data.password).then(() => {
                history.push('/admin/dashboard')
            }).catch(e => {
                console.log('Cannot log in', e)
            })

        }

    }

    //render={({ field }, errors) => <TextField type="password" {...field} {...errors?.password ? 'error' : ''} label="Password" helperText="Enter your Password address" />}
                                            //render={({ field: { value }, fieldState: { error } }) => <TextField name="email" value={value} error={!!error} helperText={error ? error.message : "Enter your email"} label="Email" />}
    //{...field}
    return (

        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Login</h4>
                                <p className={classes.cardCategoryWhite}>Enter your login information below</p>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={5} >
                                        <Controller
                                            name="email"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="email" value={value} error={!!error} helperText={error ? error.message : "Enter your email"} label="Email" />}
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5} >
                                        <Controller
                                            name="password"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { value, onChange }, fieldState: { error } }) => <TextField  onChange={onChange} name="password" type="password" value={value} error={!!error} helperText={error ? error.message : "Enter your Password address"} label="Password" />}
                                        />

                                    </GridItem>


                                </GridContainer>

                            </CardBody>

                            <CardFooter>
                                <Button variant="contained" type="submit">Sign in</Button>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </form >
        </div >

    )
}

export default LoginForm;