import Button from "components/CustomButtons/Button.js";
export default function ClientSiteSettings(props) {
    return (
        <table>
            <tbody>
                <tr>
                    <th>Export Site</th>
                    <td>
                        {
                            <Button onClick={() => props.exportSitePrivate(props.id)} variant="contained" color="primary"> Export to my reseller account</Button>
                        }

                        {
                            <Button onClick={() => props.exportSitePublic(props.id)} variant="contained" color="warning"> Export to market </Button>
                        }
                    </td>
                </tr>
 
                <tr>
                    <th>Management</th>
                    <td>
                        {
                            <Button onClick={() => props.onDelete(props.id)} variant="contained" color="danger"> Delete Site</Button>
                        }
                    </td>
                </tr>
            </tbody>
        </table>
    )
}