import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LinearProgress from '@material-ui/core/LinearProgress'

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/TabPanel';

import TextField from "@material-ui/core/TextField";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import useNotification from 'hooks/useNotification';




const useStyles = makeStyles(styles);
import styles from "styles/styles"

import { apiLoadClient, apiUpdateClient, apiCountClientSites } from "_api"


import { Controller, useForm } from "react-hook-form";

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { apiDeleteClient } from "_api/reseller";

import { a11yProps } from '_util'


const clientEditSchema = Joi.object({
  email: Joi.string().required().email({ tlds: { allow: false } }),
  first_name: Joi.string().required().min(3),
  last_name: Joi.string().required().min(3),
  city: Joi.string().allow(''),
  company: Joi.string().required().min(2),
  address: Joi.string().allow('').optional(),
  address2: Joi.string().allow('').optional(),
  country: Joi.string().allow('').optional(),
  phone: Joi.string().allow('').optional(),
  zip: Joi.string().allow('').optional(),
});

const skipFields = ['id', 'updated_at', 'created_at', 'reseller_id', 'name']


export default function ClientEdit() {
  const classes = useStyles();

  const [clientData, setClientData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  const history = useHistory();
  const { id } = useParams();

  const { addNotification } = useNotification();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const { register, handleSubmit, watch, control, setValue, formState: { errors } } = useForm(
    {
      resolver: joiResolver(clientEditSchema),
    }
  );

  const deleteClient = () => {
    if (count == 0) {
      apiDeleteClient(id).then(resp => {


        if (resp.data.status === true) {
          addNotification('Successfully deleted', 'success')
          history.push('/admin/clients')
        }
        else {
          addNotification('Updating failed', 'error')

        }


      }).catch(err => {

      })
    }
  }


  const onSubmitClient = data => {
    if (Object.keys(errors).length === 0) {
      apiUpdateClient(id, data).then(resp => {

        if (resp.data.status === true) {
          addNotification('Successfully updated', 'success')
        }
        else {
          addNotification('Updating failed', 'error')

        }
      })
    }

  }

  useEffect(() => {
    apiCountClientSites({
      client_id: id
    }).then(data => {
      setCount(data.data.count)

    })
  }, [])



  useEffect(() => {
    apiLoadClient({
      id: id
    }).then(data => {

      if (data.data.count > 0) {

        setClientData(data.data.rows[0])
        Object.keys(data.data.rows[0]).forEach(v => {
          if (!skipFields.includes(v)) {
            //if (updateFields.includes(v)) {

            if (data.data.rows[0][v] !== null) {

              setValue(v, data.data.rows[0][v])
            }
          }
        })
      }

      setLoading(false)
    })
  }, [id])

  return (
    <form onSubmit={handleSubmit(onSubmitClient)}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/admin/clients">
          Clients
        </Link>
        <Link
          color="textPrimary"
          aria-current="page"
        >
          Edit Client "{clientData?.name}""
        </Link>
        <Button href={`/admin/client/site/${clientData.id}`}>
          Sites
        </Button>
      </Breadcrumbs>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              {isLoading && <LinearProgress />}

              <AppBar position="static">
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="simple tabs example">
                  <Tab label="General Information" {...a11yProps(0)} />
                  <Tab label="Settings" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <TabPanel value={tabIndex} index={1}>
                <h3>Delete Client</h3>
                {count == 0 ? (
                  <div>
                    <p>This client has no sites created. It is safe to delete it</p>
                    <Button onClick={() => deleteClient()}>Delete Client</Button>
                  </div>

                ) : (<p className="errors">This client has {count} sites assigned to him. Please delete sites first.</p>)
                }
              </TabPanel>
              <TabPanel value={tabIndex} index={0}>
                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="company"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="company" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter company Name "} label="Company Name" fullWidth />}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="email" value={value} error={!!error} helperText={error ? error.message : "Enter your email"} label="Email" fullWidth />}
                    />

                  </GridItem>


                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="first_name"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="first_name" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter First Name "} label="First Name" fullWidth />}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="last_name"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="last_name" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter Last Name "} label="Last Name" fullWidth />}
                    />
                  </GridItem>



                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="address"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="address" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter Address line 1 "} label="Address line 1 " fullWidth />}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="address2"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="address2" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter address line 2"} label="Address line 2" fullWidth />}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={4} >
                    <Controller
                      name="country"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="country" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter country Name "} label="country Name" fullWidth />}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4} >
                    <Controller
                      name="city"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="city" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter city Name "} label="City Name" fullWidth />}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={4} >
                    <Controller
                      name="zip"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="zip" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter zip code "} label="Zip Code" fullWidth />}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={12} >
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="phone" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter phone number "} label="Phone number" fullWidth />}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={12} >

                    <Button variant="contained" color="primary" type="submit">Update Client</Button>
                  </GridItem>

                </GridContainer>
              </TabPanel>

            </CardBody>
            <CardFooter>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </form >
  );
}
