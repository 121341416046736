import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LinearProgress from '@material-ui/core/LinearProgress'

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/TabPanel';

import TextField from "@material-ui/core/TextField";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';


import AdminEditUsername from "views/Admin/AdminEditUsername"
import AdminEditPassword from "views/Admin/AdminEditPassword"
import AdminEditGeneral from "views/Admin/AdminEditGeneral"


import {a11yProps} from "_util"

const useStyles = makeStyles(styles);
import styles from "styles/styles"

import { apiLoadClientSiteAdmin, apiUpdateClientSiteAdmin } from "_api"
import { Typography } from "@material-ui/core";



export default function AdminEdit() {
  const classes = useStyles();

  const [adminData, setAdminData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  const { id } = useParams();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };



  useEffect(() => {
    apiLoadClientSiteAdmin(
      id
    ).then(data => {

      if (data.data.count > 0) {

        setAdminData(data.data.rows[0])
     }

      setLoading(false)
    })
  }, [id])

  return (

    <div>

      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/admin/clients">
          Clients
        </Link>
        <Link
          color="textPrimary"
          aria-current="page"
          href={`/admin/client/view/${adminData?.clientSite?.client?.id}`}
        >
          Client "{adminData?.clientSite?.client?.name}"
        </Link>
        <Link
          color="textPrimary"
          aria-current="page"
          href={`/admin/client/site/view/${adminData?.clientSite?.id}`}
        >
          Site "{adminData?.clientSite?.domain}"
        </Link>


        <Link
          color="textPrimary"
          aria-current="page"
        >
          Edit Admin "{adminData?.username}"
        </Link>
      </Breadcrumbs>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>

              <AppBar position="static">
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="simple tabs example">
                  <Tab label="General Information" {...a11yProps(0)} />
                  <Tab label="Settings" {...a11yProps(1)} />
                </Tabs>
              </AppBar>

              <TabPanel value={tabIndex} index={1}>
                {isLoading ? <LinearProgress /> :
                  (

                    <div>
                      <Typography>Login</Typography>
                      <AdminEditUsername username={adminData?.username} id={id} />
                      <Typography>Password</Typography>
                      <AdminEditPassword id={id} />
                    </div>
                  )
                }
              </TabPanel>
              <TabPanel value={tabIndex} index={0}>
                {isLoading ? <LinearProgress /> :
                  <AdminEditGeneral first_name={adminData.first_name} last_name={adminData?.last_name} primary_email={adminData?.primary_email} id={id} />
                }

              </TabPanel>

            </CardBody>
            <CardFooter>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer >
    </div >
  );
}
