import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import LinearProgress from '@material-ui/core/LinearProgress'


const useStyles = makeStyles(styles);
import styles from "styles/styles"
import { useParams } from "react-router-dom";

import { apiLoadClientSite, apiDeleteClientSite, apiExportClientSite } from "_api"

import AdminList from "views/Admin/AdminList"
import ClientSiteData from "views/ClientSite/ClientSiteData"
import ClientSiteSettings from "views/ClientSite/ClientSiteSettings"

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';


import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/TabPanel';

import useNotification from 'hooks/useNotification';

import {a11yProps} from "_util"


export default function ClientSiteEdit() {
  const classes = useStyles();

  const [siteData, setSiteData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  const { id } = useParams();

  const history = useHistory();

  const deleteSite = (id) => {
    alert('delete site', id)
    apiDeleteClientSite(id).then(v => {
      
          history.push(`/admin/client/site/${siteData?.client?.id}`)
    })
  }

  const exportSitePublic = (id) => {
    apiExportClientSite(id, true)
  }

  const exportSitePrivate = (id) => {
    apiExportClientSite(id, false)
  }



  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const { addNotification } = useNotification();

  useEffect(() => {
    apiLoadClientSite({
      id: id
    }).then(data => {
      if (data.data.count > 0) {
        setSiteData(data.data.rows[0])
      }
      setLoading(false)
    })
  }, [])

  return (
    <div>


      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/admin/clients">
          Clients
        </Link>
        <Link
          color="textPrimary"
          aria-current="page"
          href={`/admin/client/site/${siteData?.client?.id}`}
        >
          Client "{siteData?.client?.name}"
        </Link>
        <Link
          color="textPrimary"
          aria-current="page"
        >
          Site "{siteData?.domain}"
        </Link>

      </Breadcrumbs>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Manage Site {isLoading ? '...' : <strong>{siteData.domain}</strong>}</h4>
            </CardHeader>
            <CardBody>
              {isLoading ? <LinearProgress /> :
                <div className="site-data-wrapper">

                  <AppBar position="static">
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="simple tabs example">
                      <Tab label="General Information" {...a11yProps(0)} />
                      <Tab label="Admins" {...a11yProps(1)} />
                      <Tab label="Settings" {...a11yProps(2)} />
                    </Tabs>
                  </AppBar>

                  <TabPanel value={tabIndex} index={0}>

                    <ClientSiteData siteData={siteData} />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <AdminList id={id} />
                  </TabPanel>

                  <TabPanel value={tabIndex} index={2}>
                    <ClientSiteSettings id={id} onDelete={deleteSite} exportSitePrivate={exportSitePrivate} exportSitePublic={exportSitePublic}/>

                  </TabPanel>


                </div>
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
