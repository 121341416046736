import axios from 'axios'


export const getHeaders = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    let headers = {
        "Content-Type": "application/x-www-form-urlencoded"
    }

    if (user && user.token) {
        headers['Authorization'] = 'Bearer ' + user.token;
//        headers['x-access-token'] =  user.token;
    }

    return headers;
}

