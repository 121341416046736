const Joi = require('joi')
const schemas = {
    site_name:
        Joi.object({
            name: Joi.string().required().min(3),
        }),
 
    domain:
        Joi.object({
            domain: Joi.string().required().domain().messages({
      'any.required': `Please enter domain name - e.g. mywebsite.com`,
      'string.empty': `Please enter domain name - e.g. mywebsite.com`,
      'string.base': `Please enter domain name - e.g. mywebsite.com`,
    }),
        }),
    clientCreate:
        Joi.object({
            email: Joi.string().required().email({ tlds: { allow: false } }),
            first_name: Joi.string().required().min(3),
            last_name: Joi.string().required().min(3),
            city: Joi.string().allow(''),
            company: Joi.string().required().min(2),
            address: Joi.string().allow('').optional(),
            address2: Joi.string().allow('').optional(),
            country: Joi.string().allow('').optional(),
            phone: Joi.string().allow('').optional(),
            zip: Joi.string().allow('').optional(),
        })

}
module.exports = schemas;
