import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import LinearProgress from '@material-ui/core/LinearProgress'

import Typography from '@material-ui/core/Typography';

import styles from "styles/styles"

const useStyles = makeStyles(styles);

import { apiLoadClients } from "_api"
import { Button } from "@material-ui/core";



export default function Clients() {
  const classes = useStyles();
  const headers = ["ID", "Name", "Names", "Company", "Email", "Country", "Created", "Sites Count", "Actions"]

  const [limit, setLimit] = useState(10);
  const [listing, setListing] = useState([]);
  const [isLoading, setLoading] = useState(true);


  useEffect(() => {
    apiLoadClients({
      limit: limit
    }).then(data => {
      setListing(data.data.rows)
      setLoading(false)


    })
  }, [limit])

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Clients</h4>
          </CardHeader>
          <CardBody>
            <Typography align="right">

              <Button color="primary" variant="contained" href="/admin/client/create">Create</Button>
            </Typography>
            {isLoading && <LinearProgress />}
            <Table
              tableHeaderColor="primary"
              tableHead={headers}
              tableData={listing.map(v => {
                return [
                  v.id,
                  v.name,
                  v.first_name + ' ' + v.last_name,
                  v.company,
                  v.email,
                  v.country,
                  v.created_at,
                  v.sitesCount,
                  <span>

                    <Button href={`/admin/client/site/${v.id}`} variant="contained" color="primary"> Manage Sites</Button>
                    <Button href={`/admin/client/view/${v.id}`} variant="contained" color="secondary"> Edit Client</Button>
                  </span>
                ]
              })}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
