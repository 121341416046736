import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LinearProgress from '@material-ui/core/LinearProgress'

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/TabPanel';

import TextField from "@material-ui/core/TextField";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import useNotification from 'hooks/useNotification';

const useStyles = makeStyles(styles);
import styles from "styles/styles"

import { apiCreateClient } from "_api"


import { Controller, useForm } from "react-hook-form";

import { joiResolver } from '@hookform/resolvers/joi';

import { a11yProps } from '_util';

const validations = require('validation')

//const clientEditSchema = validations.clientCreate;


export default function ClientCreate() {
  const classes = useStyles();

  const [clientData, setClientData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const history = useHistory();
  const { id } = useParams();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const { register, handleSubmit, watch, control, setValue, formState: { errors } } = useForm(
    {
      resolver: joiResolver(validations.clientCreate),
    }
  );


  const { addNotification } = useNotification();

  const onSubmitClient = data => {
    if (Object.keys(errors).length === 0) {
      apiCreateClient(data).then(response => {
        console.log('tuka', response)
        if (response.data.status == true)
        {
          addNotification('Successfully created a new client', 'success')
          history.push(`/admin/client/site/${response.data.client.id}`)
        }
        else 
        {
          addNotification('Error when creating a client', 'error')

        }
      })
    }

  }


  return (
    <form onSubmit={handleSubmit(onSubmitClient)}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/admin/clients">
          Clients
        </Link>
        <Link
          color="textPrimary"
          aria-current="page"
        >
          Create New Client 
        </Link>
      </Breadcrumbs>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              {isLoading && <LinearProgress />}

              <AppBar position="static">
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="simple tabs example">
                  <Tab label="General Information" {...a11yProps(0)} />
                </Tabs>
              </AppBar>
             <TabPanel value={tabIndex} index={0}>
                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="company"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="company" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter company Name "} label="Company Name" fullWidth/>}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="email" value={value} error={!!error} helperText={error ? error.message : "Enter your email"} label="Email" fullWidth/>}
                    />

                  </GridItem>


                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="first_name"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="first_name" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter First Name "} label="First Name" fullWidth/>}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="last_name"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="last_name" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter Last Name "} label="Last Name" fullWidth/>}
                    />
                  </GridItem>



                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="address"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="address" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter Address line 1 "} label="Address line 1 " fullWidth/>}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={6} >
                    <Controller
                      name="address2"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="address2" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter address line 2"} label="Address line 2" fullWidth />}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={4} >
                    <Controller
                      name="country"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="country" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter country Name "} label="country Name" fullWidth/>}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4} >
                    <Controller
                      name="city"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="city" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter city Name "} label="City Name" fullWidth/>}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={4} >
                    <Controller
                      name="zip"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="zip" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter zip code "} label="Zip Code" fullWidth/>}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={12} >
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="phone" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter phone number "} label="Phone number" fullWidth/>}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={12} >
                    <Button variant="contained" type="submit" color="primary">Create Client</Button>
                  </GridItem>

                </GridContainer>
              </TabPanel>

            </CardBody>
            <CardFooter>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </form >
  );
}
