//import router from '../router'


export const handleResponse = (response) => {

    const data = response.data;
    //console.log('handle response, ', response)
    //status 401 ne stiga dotuka, mqta rejection nagore
    console.log('response', response)
    if (!data.status || data.status == 401) {

        if (response.status === 401 || data.status == 401) {
            // auto logout if 401 response returned from api
            //logout();
            //location.reload(true);
            //router.push('/admin/logout');
            alert('logout')
            Promise.reject('Force logout')
        }

   //     const error = (data && data.errors) || response.statusText;
   //     return Promise.reject(error);
    }

    //return Promise.resolve(data)
    return response
    //return data;

}
