import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import TextField from "@material-ui/core/TextField";

import useNotification from 'hooks/useNotification';

const useStyles = makeStyles(styles);
import styles from "styles/styles"

import {  apiUpdateClientSiteAdminUsername } from "_api"

import { Controller, useForm } from "react-hook-form";

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';


const adminEditUsernameSchema = Joi.object({
  username: Joi.string().required().email({ tlds: { allow: false } }),
});



export default function AdminEditUsername(props) {
  const classes = useStyles();

    const { addNotification } = useNotification();

  const { setError, handleSubmit, watch, control, setValue, formState: { errors } } = useForm(
    {
      resolver: joiResolver(adminEditUsernameSchema),
    }
  );


  const onSubmitClient = data => {
    if (Object.keys(errors).length === 0) {
      apiUpdateClientSiteAdminUsername(props.id, data).then((response) => {
        if (response.data.status === true) {

          addNotification('Successfully updated username', 'success');
        }
        else {
          const errorMsg = response.data?.message;
          setError('username', {
            type: 'server',
            message: errorMsg
          })

          addNotification('Failed updating account ' , 'error');

        }
      }).catch(err => {
          addNotification('Failed updating account ', 'error');
      })
    }

  }


  useEffect(
    () => {
      setValue('username', props.username)
    }, []
  )

  return (

    <form onSubmit={handleSubmit(onSubmitClient)}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>

              <GridContainer>

                <GridItem xs={12} sm={12} md={6} >
                  <Controller
                    name="username"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="username" value={value} error={!!error} helperText={error ? error.message : "Login username (must be a valid email address)"} label="Username" fullWidth/>}
                  />

                </GridItem>
              </GridContainer>

            </CardBody>
            <CardFooter>

              <Button variant="contained" type="submit">Update Username</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </form >
  );
}
