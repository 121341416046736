var axios = require('axios')
import { handleResponse } from '_helpers';
import { getHeaders } from '_api';

import {apiHost } from './config'

var qs = require("qs");

const LOGIN_URL = apiHost() + 'api/reseller/signin';
const LOGOUT_URL = apiHost() + '/api/auth/signout';



export const apiLogin = (postdata) => {

    console.log('apiLogin', postdata)
    return axios
        .post(LOGIN_URL, qs.stringify(postdata), {
            headers: getHeaders()
        }).then(handleResponse)
}

export const apiLogout = () => {
    console.log('apiLogout' )
    return axios
        .get(LOGOUT_URL,  {
            headers: getHeaders()
        }).then(handleResponse)

}
