import { apiLogin, apiLogout } from '_api'

export const userService = {
    login,
    logout,
    isLogged,
    hasCredential
};

function isLogged() {
    if (localStorage.getItem("user") === null) {
        return false
    }
    return true
}

function hasCredential(credential) {

    let user = JSON.parse(localStorage.getItem('user'));
    return user.credentials.include(credential);
}

function login(email, password) {
    return apiLogin({
        email: email,
        password: password
    })
        .then(response => {
            // login successful if there's a jwt token in the response
            const user = response.data.user
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            else {
                Promise.reject('No token returned by api')
            }

            return user;
        }).

        catch(err => {
            console.log('Login Error', err)
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
//    apiLogout();
}

