import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LinearProgress from '@material-ui/core/LinearProgress'

import { Link, useHistory } from 'react-router-dom'

import TextField from "@material-ui/core/TextField";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import useNotification from 'hooks/useNotification';


const useStyles = makeStyles(styles);
import styles from "styles/styles"

import { apiLoadClientSite, apiCreateClientSiteAdmin } from "_api"


import { Controller, useForm } from "react-hook-form";

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
//const updateFields = ['primary_email', 'first_name', 'last_name']


export default function AdminCreate() {
  const classes = useStyles();

  const history = useHistory();
  const [adminData, setAdminData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [siteData, setSiteData] = useState([]);
  const { id } = useParams();

  const { addNotification } = useNotification();

  const adminEditSchema = Joi.object({
    primary_email: Joi.string().required().email({ tlds: { allow: false } }),
    first_name: Joi.string().required().min(3),
    last_name: Joi.string().required().min(3),

    username: Joi.string().required().email({ tlds: { allow: false } }),

    password: Joi.string().min(8).max(15).required(),
    password_confirm: Joi.any().equal(Joi.ref('password')).required().label('Confirm password').messages({ 'any.only': '{{#label}} does not match' })
  });

  const updateFields = ['primary_email', 'first_name', 'last_name', 'username', 'password', 'password_confirm']

  const { setError, handleSubmit, watch, control, setValue, formState: { errors } } = useForm(
    {
      resolver: joiResolver(adminEditSchema),
    }
  );


  const onSubmitGeneral = data => {
    console.log('onsubmit client')
    if (Object.keys(errors).length === 0) {
      apiCreateClientSiteAdmin(id, data).then(response => {
        if (response.data.status === true) {

          addNotification('Admin account successfully created', 'success')
          history.push(`/admin/client/site/admin/edit/${response.data.admin.id}`)
        }
        else {
          updateFields.forEach(field => {

            if (response.data.errors[field] != undefined) {

              setError(field, {
                type: 'server',
                message: response.data.errors[field]
              })
            }

          })

        }


      })
    }

  }




  useEffect(() => {
    apiLoadClientSite({
      id: id
    }).then(data => {
      if (data.data.count > 0) {
        setSiteData(data.data.rows[0])
      }
      setLoading(false)
    })
  }, [])


  return (

    <div>

      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/admin/clients">
          Clients
        </Link>
        <Link
          color="textPrimary"
          aria-current="page"
          href={`/admin/client/view/${siteData?.client?.id}`}
        >
          Client "{siteData?.client?.name}"
        </Link>
        <Link
          color="textPrimary"
          aria-current="page"
          href={`/admin/client/site/view/${siteData?.id}`}
        >
          Site "{siteData?.domain}"
        </Link>


        <Link
          color="textPrimary"
          aria-current="page"
        >
          Create New Admin
        </Link>
      </Breadcrumbs>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>

              {isLoading ? <LinearProgress /> :

                <form onSubmit={handleSubmit(onSubmitGeneral)}>

                  <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >
                      <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="username" value={value} error={!!error} helperText={error ? error.message : "Login username (must be a valid email address)"} label="Username" fullWidth />}
                      />

                    </GridItem>

                    <GridItem xs={12} sm={12} md={6} >
                      <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="password" type="password" value={value} error={!!error} helperText={error ? error.message : "Password"} label="password" fullWidth />}
                      />

                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                      <Controller
                        name="password_confirm"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="password_confirm" type="password" value={value} error={!!error} helperText={error ? error.message : "Password confirmation"} label="password" fullWidth />}
                      />

                    </GridItem>





                    <GridItem xs={12} sm={12} md={6} >
                      <Controller
                        name="primary_email"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="primary_email" value={value} error={!!error} helperText={error ? error.message : "Enter your email"} label="Email" fullWidth />}
                      />

                    </GridItem>


                    <GridItem xs={12} sm={12} md={6} >
                      <Controller
                        name="first_name"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="first_name" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter First Name "} label="First Name" fullWidth />}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6} >
                      <Controller
                        name="last_name"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => <TextField onChange={onChange} name="last_name" type="text" value={value} error={!!error} helperText={error ? error.message : "Enter Last Name "} label="Last Name" fullWidth />}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} >
                      <Button variant="contained" color="primary" type="submit">Create Admin</Button>
                    </GridItem>

                  </GridContainer>
                </form >


              }

            </CardBody>
            <CardFooter>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer >
    </div >
  );
}
